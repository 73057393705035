import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Linking IVA And Hybrid`}</h1>
    <p>{`As a blended student, I've seen both worlds this year: Irvine Virtual Academy and the Hybrid model. I do some of my classes in-person, and some virtually. Something I'm often told, and I feel it too, is that IVA is typically neglected in a lot of decision-making. I'm not sure how school will look for next year, if there will be an option for IVA and how many students will take it, but regardless, we need to make sure we're catering to ALL Irvine High students, and not just the ones that come in-person to school.`}</p>
    <p>{`This means that we're livestreaming events, offering the same opportunities to people who are online and in-person, and doing our best to bridge the gap between the two. It's important to me that IVA students feel like they're welcome at school, have a voice in student government, and are given similar (if not the same) opportunities as people who come in-person.`}</p>
    <p>{`It's all up in the air about what next year will look like, but I just wanted you to know that is something that, if chosen as ASB President, I will prioritize. How you learn and where you do it should not affect your participation in school events!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      